const localUsername = localStorage.getItem('paysave_username');
const firstName = localUsername?.split(" ")[0]?.trim();
const firstNameCapitalized = firstName?.charAt(0)?.toUpperCase() + firstName?.slice(1);
const currentHour = new Date().getHours();

const writeGreetingMessage = () => {
    let greeting = '';
    const suffix = firstNameCapitalized ? `, ${firstNameCapitalized}` : '';

    if (currentHour >= 5 && currentHour < 12) {
        greeting = `Good morning${suffix}`;
    }
    else if (currentHour >= 12 && currentHour < 17) {
        greeting = `Good afternoon${suffix}`;
    }
    else if (currentHour >= 17 && currentHour <= 23) {
        greeting = `Good evening${suffix}`;
    }
    else {
        greeting = `One moment${suffix}`;
    }

    return greeting;
}

export default writeGreetingMessage;
