import React, { useState, useEffect, useRef } from "react";
import "@fontsource-variable/inter";
import "@fontsource/roboto-slab/200.css";
import "@fontsource/roboto-slab/400.css";
import "./styles/master.scss";
import "./styles/App.scss";
import PreferencesContext from "./contexts/PreferencesContext";
import { handleSignIn } from "./functions/handleSignin";
import { authentication, storeDB } from "./firebaseConfig";
import {
  collection,
  doc,
  addDoc,
  onSnapshot,
  orderBy,
  query,
  where,
  updateDoc,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { motion, AnimatePresence } from "framer-motion";
import Fuse from 'fuse.js';
import { useLongPress } from 'use-long-press';

import coinIcon from "./images/icons/coin.svg";
import coinPressedIcon from "./images/icons/coin_pressed.svg";
import totalIcon from "./images/icons/total.svg";

import art from "./images/art.jpg";

import useSound from "use-sound";
import { data as currencyCodes } from 'currency-codes';
import recordSavedSound from "./sounds/record_saved.wav";
import Tabbar from "./components/Tabbar";
import writeGreetingMessage from "./functions/writeGreetingMessage";
import Snackbar from "./components/Snackbar";
import Switch from "./components/Switch";
import CloseIcon from "./icons/CloseIcon";
import AddIcon from "./icons/AddIcon";
import PreviousIcon from "./icons/PreviousIcon";
import NextIcon from "./icons/NextIcon";

import Avvvatars from "avvvatars-react";
import useCurrencyCode from "./hooks/useCurrencyCode";
import useScrollLock from "./hooks/useScrollLock";
import Search from "./components/Search";
import { useRecoilValue, useRecoilState } from "recoil";
import searchAtom from "./atoms/SearchAtom";
import monthReportAtom from "./atoms/MonthReportAtom";
import ReportTrend from "./components/Reports/ReportTrend";
import Dialog from "./components/Dialog/";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(); //current user

  const { currencyCode, setCurrencyCode } = useCurrencyCode();

  const localUsername = () => {
    let currentUsername = localStorage.getItem("paysave_username");
    if (currentUsername) {
      return currentUsername;
    } else {
      return null;
    }
  };

  const [loadingMessage, setLoadingMessage] = useState(writeGreetingMessage());

  const [soundsEnabled, setSoundsEnabled] = useState(() => {
    const savedState = localStorage.getItem("paysave_soundsEnabled");
    return JSON.parse(savedState) ?? true;
  });

  const toggleSounds = () => {
    setSoundsEnabled((current) => !current);
  };

  useEffect(() => {
    localStorage.setItem("paysave_soundsEnabled", soundsEnabled);
  }, [soundsEnabled]);

  const [playRecordSavedSound] = useSound(recordSavedSound, {
    volume: 0.5,
  });

  const [records, setRecords] = useState([]); //array of data from firebase
  const [type, setType] = useState("paid"); //current value for type in new entry
  const [newEntryVisible, setNewEntryVisible] = useState(false); //new entry form visibility
  const addEntryFormRef = useRef();

  const [snackbar, setSnackbar] = useState({
    visible: false,
    message: "Hello!",
  });

  const [recordsFilter, setRecordsFilter] = useState(() => {
    let currentFilter = localStorage.getItem("paysave_activeTab");
    if (currentFilter) {
      return currentFilter;
    } else {
      return "paid";
    }
  });

  const [settlementRequest, setSettlementRequest] = useState("");
  const [editRequest, setEditRequest] = useState({});

  const [totalsToPay, setTotalsToPay] = useState(0);
  const [totalsToReceive, setTotalsToReceive] = useState(0);

  const editEntryFormRef = useRef();
  const [editEntryVisible, setEditEntryVisible] = useState(false); //edit entry form visibility

  // set activeTab to localStorage
  useEffect(() => {
    localStorage.setItem("paysave_activeTab", recordsFilter);
  }, [recordsFilter]);

  // add to user's records
  const addRecord = async (addedOn, amt, date, desc, name, method, type) => {
    try {
      const docRef = await addDoc(
        collection(storeDB, "users/" + user.uid + "/records/"),
        {
          timestamp: addedOn,
          amt,
          date,
          desc,
          name,
          method,
          type,
        }
      );
      setSnackbar((prevState) => ({
        visible: true,
        message: `Saved`,
      }));

      soundsEnabled && playRecordSavedSound();
    } catch (e) {
      console.log(`Error ${e}`);
    }
  };

  // return object with time
  const getTime = () => {
    let today = new Date();

    return {
      timestamp: Date.now(),
      date: today.toLocaleDateString(),
      year: today.getFullYear(),
      month: today.toDateString().split(" ")[1],
      day: today.getDay(),
      hour: today.getHours().toString().padStart(2, "0"),
      minute: today.getMinutes(),
    };
  };

  // return day of the week
  const getWeekday = (day) => {
    let weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return weekday[day];
  };

  // return month name
  const getMonthName = (month) => {
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthNames[month];
  };

  const getToday = () => {
    let today = new Date();
    return (
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + today.getDate()).slice(-2)
    );
  };

  // get user records based on filter
  useEffect(() => {
    if (!user) return;
    showMinimumRecords();

    let recordsData;
    let futureRecords = Boolean(recordsFilter === 'to-pay' || recordsFilter === 'to-receive');

    const q = query(
      collection(storeDB, "users/" + user.uid + "/records/"),
      where("type", "==", recordsFilter),
      orderBy("date", futureRecords ? "desc" : "asc"),
      orderBy("timestamp", futureRecords ? "desc" : "asc")
    );
    recordsData = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (change.doc.data().settled === true) return;
          let newRecord = {
            ...change.doc.data(),
            id: change.doc.id,
          };
          setRecords((prev) => [newRecord, ...prev]);
        }
      });

      setIsLoading((current) => false);
    });

    return () => {
      // unsubscribe to the recordsData listener
      recordsData();
      setRecords((current) => []);
    };
  }, [user, recordsFilter]);

  const getRelativeTimeUnits = (days) => {
    let differenceDayCount = Math.abs(days);

    if (differenceDayCount > 359) {
      return {
        value: Math.floor(days / 365),
        label: "year",
      };
    }

    if (differenceDayCount > 59) {
      return {
        value: Math.floor(days / 30),
        label: "month",
      };
    }

    if (differenceDayCount > 6) {
      return {
        value: Math.floor(days / 7),
        label: "week",
      };
    }

    return {
      value: days,
      label: "day",
    };
  };

  const getRelativeDateText = (date) => {
    let currentTime = Date.now();
    let difference = new Date(date).getTime() - currentTime;
    let differenceInSeconds = difference / 1000;
    let differenceInMinutes = differenceInSeconds / 60;
    let differenceInHours = differenceInMinutes / 60;
    let differenceInDays = differenceInHours / 24;
    let roundedDifference = Math.ceil(differenceInDays);
    let differenceUnits = getRelativeTimeUnits(roundedDifference);
    let differenceText = new Intl.RelativeTimeFormat("en", {
      numeric: "auto",
      style: "long",
    }).format(differenceUnits.value, differenceUnits.label);

    return differenceText;
  };

  const groupRecordsByDate = (records) => {
    let groupedRecords = [];
    for (let i = 0; i < records.length; i++) {
      let date = records[i].date;
      let index = groupedRecords.findIndex((group) => group.date === date);
      if (index === -1) {
        groupedRecords.push({
          date,
          relativeDate:
            recordsFilter === "to-pay" || recordsFilter === "to-receive"
              ? getRelativeDateText(date)
              : "",
          records: [records[i]],
        });
      } else {
        groupedRecords[index].records.push(records[i]);
      }
    }
    return groupedRecords;
  }

  // group records based on date
  const [groupedRecords, setGroupedRecords] = useState([]);
  useEffect(() => {
    setGroupedRecords(groupRecordsByDate(records));
  }, [records]);

  // set user on auth success
  useEffect(() => {
    authentication.onAuthStateChanged((user) => {
      if (user) {
        setUser((current) => user);
        localStorage.setItem("paysave_username", user.displayName);
      } else {
        setUser((current) => null);
        localStorage.removeItem("paysave_username");
        setIsLoading((current) => false);
      }
      setLoadingMessage((current) => "Getting your records...");
    });
  }, []);

  // handle add entry form and settlement request
  const addEntry = (e) => {
    e.preventDefault();

    let entryAmt = e.target.elements.amt.value;
    let entryDesc = e.target.elements.desc.value.trim();
    let entryName = e.target.elements.name.value.trim();
    let entryMethod = e.target.elements.method.value.trim();
    let entryDate = e.target.elements.date.value;
    let entryTimestamp = getTime().timestamp;

    setNewEntryVisible((current) => false);

    addRecord(
      entryTimestamp,
      entryAmt,
      entryDate,
      entryDesc,
      entryName,
      entryMethod,
      type
    );

    if (settlementRequest) {
      try {
        const docRef = updateDoc(
          doc(storeDB, "users/" + user.uid + "/records/", settlementRequest),
          {
            settled: true,
          }
        );
      } catch (e) {
        console.log(`Error ${e}`);
      }

      setRecords((prev) =>
        prev.filter((record) => record.id !== settlementRequest)
      );
      setSettlementRequest((current) => "");
    }

    e.target.reset();
  };

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to log out?")) {
      authentication.signOut();
      setRecords((current) => []);
      setNames((current) => []);
      setMethods((current) => []);
      setProfileVisible((current) => false);
    }
  };

  const openNewEntry = () => {
    setNewEntryVisible((current) => true);
    setType(recordsFilter);
  };

  const closeNewEntry = () => {
    if (settlementRequest) {
      setSettlementRequest((current) => "");
    }
    if (copyEntryVisible) {
      setCopyEntryVisible((current) => false);
    }
    setNewEntryVisible((current) => false);
  };

  // attach settlement info to add entry form
  const handleSettlement = (
    e,
    settlementType,
    settlementAmt,
    settlementDesc,
    settlementName,
    settlementMethod,
    settlementID
  ) => {
    e.stopPropagation();
    setNewEntryVisible((current) => true);
    setSettlementRequest((current) => settlementID);

    if (settlementType === "to-pay") {
      setType("paid");
    } else {
      setType("received");
    }

    setTimeout(() => {
      addEntryFormRef.current.elements.amt.value = settlementAmt;
      addEntryFormRef.current.elements.desc.value = settlementDesc;
      addEntryFormRef.current.elements.name.value = settlementName || "";
      addEntryFormRef.current.elements.method.value = settlementMethod || "";
    }, 10);
  };

  const [monthTotals, setMonthTotals] = useState([]);
  const [monthReport, setMonthReport] = useRecoilState(monthReportAtom);

  const openMonthReport = (month, index) => {
    setMonthReport(calculateMonthReports(month, index));
  }
  const closeMonthReport = () => {
    setMonthReport(null);
  };

  // calculate and return the percentage difference between the totals of two monnths
  const calculateTrendPercentage = (previousMonth, currentMonth) => {
    return ((currentMonth - previousMonth) / previousMonth) * 100;
  };

  const percentageStringFrom = (number) => {
    return `${Intl.NumberFormat({ style: "currency", currency: currencyCode }).format(
      Math.abs(number.toFixed(0))
    )}%`;
  };

  const getTrendDescription = (trend) => {
    let description = "";
    if (trend === 0) {
      description = "same";
    }
    if (trend > 0) {
      description = "upper";
    }
    if (trend < 0) {
      description = "lower";
    }

    return description;
  };

  // add trend percentage to monthly totals
  const addTrendToArray = (monthlyTotals) => {
    // if length < 2, there's nothing to calculate
    if (monthlyTotals.length < 2) return monthlyTotals;

    let previousTotal = 0;
    let newArray = [];

    monthlyTotals.reverse().map((month, index) => {
      if (index === 0) {
        newArray.unshift({
          ...month,
        });
      } else {
        let trendPercent = calculateTrendPercentage(previousTotal, month.amt);
        let trendAmt = currencyStringFrom(Math.abs(Math.round(month.amt - previousTotal)));
        newArray.unshift({
          ...month,
          trend: {
            percent: percentageStringFrom(trendPercent),
            description: getTrendDescription(trendPercent),
            amt: trendAmt,
          },
        });
      }
      previousTotal = month.amt;
    });

    return newArray;
  };

  // calculate total amt for future transactions
  const calculateFutureTotals = () => {
    if (recordsFilter === "to-pay" || recordsFilter === "to-receive") {
      let totalAmt = 0;

      records.forEach((record) => {
        totalAmt += parseFloat(record.amt);
      });

      if (recordsFilter === "to-pay") {
        setTotalsToPay((current) => totalAmt);
      }
      if (recordsFilter === "to-receive") {
        setTotalsToReceive((current) => totalAmt);
      }
    }
  }

  useEffect(() => {
    calculateFutureTotals();
  }, [records]);

  const showReports = () => {
    setReportsVisible((current) => true);
  };

  const hideReports = () => {
    setReportsVisible((current) => false);
  };

  const pickRandomFromArray = (sourceArray) => {
    return sourceArray[Math.floor(Math.random() * sourceArray.length)];
  };

  // toys for empty state
  const toys = [
    "🪁",
    "✨",
    "✈️",
    "🌤️",
    "🌈",
    "🪂",
    "🍃",
    "🫖",
    "🎈",
    "🌻",
    "🚌",
    "🎶",
    "🪄",
    "🎪",
    "💎",
    "🔮",
    "🌷",
    "📻",
    "🎻",
    "🍰",
  ];
  const currentToy = useRef(pickRandomFromArray(toys));

  // adjective for empty state
  const adjectives = [
    "bright",
    "surprising",
    "pink",
    "memorable",
    "smiling",
    "breezy",
    "chill",
    "relieving",
    "smooth",
    "creative",
    "loving",
    "lucky",
    "refreshing",
    "comforting",
    "grateful",
    "pleasing",
    "nostalgic",
    "sweet",
    "kind",
    "once in a lifetime",
    "healthy",
  ];
  const currentAdjective = useRef(pickRandomFromArray(adjectives));

  //suggestions for name in entry form
  const [names, setNames] = useState(() => {
    let currentNames = JSON.parse(localStorage.getItem("paysave_names"));
    if (currentNames) {
      return currentNames;
    } else {
      return [];
    }
  });

  useEffect(() => {
    if (!user) return;
    localStorage.setItem("paysave_names", JSON.stringify(names));
  }, [names]);

  const getNames = async () => {
    if (!user) return;

    const q = query(
      collection(storeDB, "users/" + user.uid + "/records/"),
      orderBy("name", "asc")
    );
    let allNames = [];
    let allMethods = [];

    let namesData = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          let name = change.doc.data().name;
          if (name) {
            allNames.push(name);
            setNames([...new Set(allNames)]);
          }

          let method = change.doc.data().method;
          if (method) {
            allMethods.push(change.doc.data().method);
            setMethods([...new Set(allMethods)]);
          }
        }
      });
    });

    return () => {
      // unsubscribe to the namesData listener
      namesData();
      setNames([]);
    };
  };

  // get names from user records
  useEffect(() => {
    getNames();
  }, [user]);

  //suggestions for payment methods in entry form
  const [methods, setMethods] = useState(() => {
    let currentMethods = JSON.parse(localStorage.getItem("paysave_methods"));
    if (currentMethods) {
      return currentMethods;
    } else {
      return [];
    }
  });

  useEffect(() => {
    if (!user) return;
    localStorage.setItem("paysave_methods", JSON.stringify(methods));
  }, [methods]);

  // entry form animation values
  const entryFormVariants = {
    initial: {
      y: "150%",
    },
    animate: {
      y: "0%",
      transition: {
        type: "spring",
        damping: 80,
        stiffness: 1000,
        mass: 0.5,
      },
    },
    exit: {
      y: "150%",
      transition: {
        type: "spring",
        duration: 0.4,
      },
    },
  };

  const editEntryAddedOnDateRef = useRef();

  // open edit entry on record click
  const handleRecordClick = (e, record) => {
    e.stopPropagation();

    setEditRequest((current) => record);
    setEditEntryVisible((current) => true);

    setTimeout(() => {
      setType(record.type);
      editEntryFormRef.current.elements.amt.value = record.amt;
      editEntryFormRef.current.elements.desc.value = record.desc;
      editEntryFormRef.current.elements.name.value = record.name || "";
      editEntryFormRef.current.elements.method.value = record.method || "";
      editEntryFormRef.current.elements.date.value = record.date;
      editEntryAddedOnDateRef.current.innerText = `Added ${fullDateFormat.format(
        new Date(record.timestamp)
      )}`;
    }, 10);
  };

  const [copyEntryVisible, setCopyEntryVisible] = useState(false);

  const bind = useLongPress((e, cb) => {
    if (!cb.context) return;
    setCopyEntryVisible((current) => true);
    copyRecord(cb.context);
  });

  const copyRecord = (record) => {
    if (!record) return;
    setNewEntryVisible((current) => true);
    console.log(record)

    setTimeout(() => {
      setType(record.type);
      addEntryFormRef.current.elements.amt.value = record.amt;
      addEntryFormRef.current.elements.desc.value = record.desc;
      addEntryFormRef.current.elements.name.value = record.name || "";
      addEntryFormRef.current.elements.method.value = record.method || "";
      addEntryFormRef.current.elements.date.value = new Date().toISOString().split('T')[0];
    }, 10);
  };

  const handleDeleteRecordClick = () => {
    if (!editRequest) return;

    if (
      window.confirm(
        `Are you sure you want to delete this record? You can't undo this action.`
      )
    ) {
      setEditEntryVisible((current) => false);

      try {
        const docRef = deleteDoc(
          doc(storeDB, "users/" + user.uid + "/records/", editRequest.id)
        ).then(() => {
          // after deleting record
          setRecords((prev) => prev.filter((r) => r.id !== editRequest.id));
          setSnackbar((prevState) => ({
            visible: true,
            message: `Deleted`,
          }));
          setEditRequest((current) => { });
        });
      } catch (e) {
        console.log(`Error ${e}`);
      }
    }
  };

  const closeEditEntry = () => {
    setEditRequest((current) => { });
    setEditEntryVisible((current) => false);
  };

  const saveEditEntryChanges = (e) => {
    e.preventDefault();

    const formData = new FormData(editEntryFormRef.current);
    let docRef;

    setEditEntryVisible((current) => false);

    try {
      docRef = updateDoc(
        doc(storeDB, "users/" + user.uid + "/records/", editRequest.id),
        {
          amt: formData.get("amt"),
          desc: formData.get("desc"),
          name: formData.get("name"),
          method: formData.get("method"),
          date: formData.get("date"),
          type: type,
          lastUpdated: getTime().timestamp,
        }
      ).then(() => {
        // after updating record

        // if type has changed, delete record from records array
        if (type !== editRequest.type) {
          setRecords((prev) => prev.filter((r) => r.id !== editRequest.id));
        }

        setRecords((prev) =>
          prev.map((r) => {
            if (r.id === editRequest.id) {
              r.amt = formData.get("amt");
              r.desc = formData.get("desc");
              r.name = formData.get("name");
              r.method = formData.get("method");
              r.date = formData.get("date");
              r.type = type;
            }
            return r;
          })
        );

        setSnackbar((prevState) => ({
          visible: true,
          message: `Saved`,
        }));
        soundsEnabled && playRecordSavedSound();

        setEditRequest((current) => { });
      });
    } catch (e) {
      console.log(`Error ${e}`);
    }

    return () => {
      // unsubscribe to the docRef listener
      docRef();
    };
  };

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      // fetch the hello-inspector script from github to show credits on the console.
      fetch(
        "https://raw.githubusercontent.com/akshar-dave/akshar-dave/main/helloInspector.js"
      )
        .then((res) => res.text())
        .then((text) => {
          eval(text);
        });
    }
  }, []);

  const currencyStringFrom = (amt) => {
    let string = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: currencyCode,
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(amt);
    return string;
  };

  function getCurrencySymbol() {
    return currencyStringFrom(0).slice(0, -1);
  }

  const [profileVisible, setProfileVisible] = useState(false);
  const closeProfile = () => {
    setProfileVisible(false);
  };

  const getGroupRecordsTotal = (group) => {
    let total = 0;
    group.forEach((r) => {
      total += parseFloat(r.amt);
    });
    return total;
  };

  const [monthStartDate, setMonthStartDate] = useState(() => {
    const savedValue = localStorage.getItem('monthStartDate');
    return savedValue !== null ? parseInt(savedValue, 10) : 1;
  });

  useEffect(() => {
    localStorage.setItem('monthStartDate', monthStartDate);
  }, [monthStartDate]);

  const handleChange = (event) => {
    setMonthStartDate(parseInt(event.target.value, 10));
  };

  const calculateMonthlyTotals = () => {
    let monthlyTotals = [];

    records.forEach((record) => {
      let [recordYear, recordMonth, recordDay] = record.date.split("-").map(Number);

      if (recordDay < monthStartDate) {
        recordMonth -= 1;
        if (recordMonth === 0) {
          recordMonth = 12;
          recordYear -= 1;
        }
      }

      let monthName = `${getMonthName(recordMonth - 1)} ${recordYear}`;
      let index = monthlyTotals.findIndex((month) => month.month === monthName);

      if (index === -1) {
        monthlyTotals.push({
          month: monthName,
          amt: parseFloat(record.amt),
          records: [record],
        });
      } else {
        monthlyTotals[index].amt += parseFloat(record.amt);
        monthlyTotals[index].records.push(record);
      }
    });

    // Add trends if necessary
    if (recordsFilter === "paid" || recordsFilter === "received") {
      monthlyTotals = addTrendToArray(monthlyTotals);
    }

    setMonthTotals((current) => monthlyTotals);
  };


  const calculateMonthReports = (month, index) => {
    const descGroup = {};
    const nameGroup = {};
    const methodGroup = {};

    const monthWithReports = { ...month };

    month.records.forEach((record) => {
      const { desc, name, method, amt } = record;
      const descKey = desc.toLowerCase().trim();
      const nameKey = name?.toLowerCase().trim();
      const methodKey = method?.toLowerCase().trim();
      const parsedAmt = parseFloat(amt);

      if (!descGroup[descKey]) {
        descGroup[descKey] = { ...record, amt: parsedAmt };
      } else {
        descGroup[descKey].amt += parsedAmt;
      }

      if (!nameGroup[nameKey]) {
        nameGroup[nameKey] = { ...record, amt: parsedAmt };
      } else {
        nameGroup[nameKey].amt += parsedAmt;
      }

      if (!methodGroup[methodKey]) {
        methodGroup[methodKey] = { ...record, amt: parsedAmt };
      } else {
        methodGroup[methodKey].amt += parsedAmt;
      }
    });

    monthWithReports.descGroup = Object.values(descGroup).sort((a, b) => b.amt - a.amt);
    monthWithReports.nameGroup = Object.values(nameGroup).sort((a, b) => b.amt - a.amt);
    monthWithReports.methodGroup = Object.values(methodGroup).sort((a, b) => b.amt - a.amt);

    monthWithReports.descGroup.hasRecords = monthWithReports.descGroup.some(record => record.desc.trim() !== '');
    monthWithReports.nameGroup.hasRecords = monthWithReports.nameGroup.some(record => record.name?.trim() !== '');
    monthWithReports.methodGroup.hasRecords = monthWithReports.methodGroup.some(record => record.method?.trim() !== '');

    if (monthTotals[index + 1]) {
      monthWithReports.lastMonth = monthTotals[index + 1].month;
    }

    return monthWithReports;
  }

  const [reportsVisible, setReportsVisible] = useState(false);

  useEffect(() => {
    if (!reportsVisible) return;
    let timer = setTimeout(() => calculateMonthlyTotals(), 200);

    return () => clearTimeout(timer);
  }, [records, reportsVisible]);

  const { toggleScroll } = useScrollLock();

  useEffect(() => {
    toggleScroll(!reportsVisible);
  }, [reportsVisible]);

  const [firstRecordDate, setFirstRecordDate] = useState(() => {
    let savedDate = localStorage.getItem("paysave_firstRecordDate");
    if (savedDate) {
      return savedDate;
    } else {
      return "";
    }
  });

  // get first record date from user's records
  useEffect(() => {
    if (!user) return;
    if (firstRecordDate) return;

    let firstRecordListener;

    const q = query(
      collection(storeDB, "users/" + user.uid + "/records/"),
      orderBy("timestamp", "asc"),
      limit(1)
    );

    firstRecordListener = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let firstRecord = change.doc.data();
        if (!firstRecord) return;
        setFirstRecordDate((prev) => firstRecord.timestamp);
      });
    });

    return () => {
      // unsubscribe to the firstRecordListener
      firstRecordListener();
    };
  }, [user]);

  useEffect(() => {
    localStorage.setItem("paysave_firstRecordDate", firstRecordDate);
  }, [firstRecordDate]);

  const fullDateFormat = new Intl.DateTimeFormat("en-GB", {
    dateStyle: "full",
  });

  const MINIMUM_RECORD_COUNT = 10;
  const [maxVisibleRecords, setMaxVisibleRecords] =
    useState(MINIMUM_RECORD_COUNT);

  const showMinimumRecords = () => {
    setMaxVisibleRecords((current) => MINIMUM_RECORD_COUNT);
  };

  const showAllRecords = () => {
    setMaxVisibleRecords((current) => 180);
  };

  const handleInviteBtn = async () => {
    try {
      await navigator.share({
        title: "Befriend your money",
        text: "A good habit for our future. Make easy notes of how your money flows on Paysave. Give it a try! ❤️",
        url: "https://paysave.app",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleEntryFormDragEnd = (e, { offset }) => {
    if (offset.y > 100) {
      closeNewEntry();
      closeEditEntry();
      closeProfile();
      hideReports();
    }
  };

  const loadingVariants = {
    initial: {
      opacity: 0,
      scale: 1.05,
    },
    animate: {
      scale: 1.05,
      opacity: 1,
      transition: {
        duration: 1,
        ease: [0.3, 0.45, 0, 1],
      },
    },
    exit: {
      scale: 1,
      opacity: 0,
      transition: {
        duration: 1,
        ease: [0.4, 0.24, 0.16, 0.97],
      },
    }
  }

  const loadingMessageVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 1, ease: 'easeOut' } },
  }

  const handleCurrencyChange = (e) => {
    setCurrencyCode(current => e.target.value);
  };

  const getWeekNumber = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 1);
    const weekNumber = Math.floor((now - startOfYear) / (7 * 24 * 60 * 60 * 1000));

    // Add 1 to the week number as the first week starts from 1st January
    return weekNumber + 1;
  }

  const sortRecords = (records) => {
    let order = "asc";
    if (recordsFilter === 'paid' || recordsFilter === 'received') order = "desc";

    if (order === "asc") {
      return records.sort((a, b) => new Date(a.date) - new Date(b.date));
    }
    return records.sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  const search = useRecoilValue(searchAtom);
  const searchConfig = {
    keys: ['name', 'desc'],
    threshold: 0.2,
  };

  const filterBySearch = (records) => {
    if (!records.length) return records;
    if (!search) return records;


    const finder = new Fuse(records, searchConfig);
    return sortRecords(finder.search(search).map((item) => item.item));
  }

  const newEntryAmtRef = useRef();
  const newEntryDescRef = useRef();
  const newEntryNameRef = useRef();
  const newEntryMethodRef = useRef();
  const newEntryDateRef = useRef();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("newRecord")) setNewEntryVisible(true);
  }, []);

  const [dayLabel, setDayLabel] = useState(new Date().toLocaleDateString('en-US', { weekday: 'long' }));

  const updateDayLabel = () => {
    const currentDate = new Date(newEntryDateRef.current.value);
    setDayLabel(currentDate.toLocaleDateString('en-US', { weekday: 'long' }));
  };

  useEffect(() => {
    setDayLabel(new Date().toLocaleDateString('en-US', { weekday: 'long' }))
  }, [newEntryVisible]);

  return (
    <div>
      <PreferencesContext.Provider
        value={{
          user,
          isLoading,
          recordsFilter,
          setRecordsFilter,
          snackbar,
          setSnackbar,
          setReportsVisible,
        }}
      >
        {/* loading state */}
        <AnimatePresence>
          {localUsername && isLoading && (
            <motion.div
              className="loading"
              variants={loadingVariants}
              initial='initial'
              animate='animate'
              exit='exit'
            >
              <motion.span className="loading-message"
                variants={loadingMessageVariants}
                initial='initial'
                animate='animate'
              >{loadingMessage}</motion.span>
              <motion.span className="loading-date"
                variants={loadingMessageVariants}
                initial='initial'
                animate='animate'
              >
                {fullDateFormat.format(new Date())}
              </motion.span>
              <motion.span className="loading-week-number"
                variants={loadingMessageVariants}
                initial='initial'
                animate='animate'
              >
                — Week {getWeekNumber()} —
              </motion.span>
            </motion.div>
          )}
        </AnimatePresence>

        {/* snackbar */}
        <Snackbar />

        <main>
          {!isLoading && (
            <header id="header">
              <div className={`header-wrapper ${user ? 'app-header' : ''}`}>
                <motion.h1
                  className={(recordsFilter === "paid" || recordsFilter === "to-pay") ? "pay" : "receive"}
                  initial={
                    reportsVisible
                      ? { x: "20px", opacity: 0.5 }
                      : { x: 0, opacity: 1 }
                  }
                  animate={
                    reportsVisible
                      ? { x: "20px", opacity: 0.5 }
                      : { x: 0, opacity: 1 }
                  }
                  transition={{ duration: 0.4, ease: "circOut" }}
                >
                  {user ? `${recordsFilter.replace("-", " ")}` : "Paysave"}

                  <AnimatePresence key={search}>
                    {/* Total amount display */}
                    {user && (
                      <motion.p
                        initial={{ opacity: 0, x: '-10%', }}
                        animate={{ opacity: 1, x: '0%', }}
                        exit={{ opacity: 0, x: '10%', }}
                        transition={{ type: 'spring', bounce: 0.1 }}
                        className="total-amt"
                      >
                        {search ? (
                          // Show search results total
                          currencyStringFrom(getGroupRecordsTotal(filterBySearch(records)))
                        ) : recordsFilter === "to-pay" ? (
                          // Show to-pay total
                          currencyStringFrom(totalsToPay)
                        ) : recordsFilter === "to-receive" ? (
                          // Show to-receive total  
                          currencyStringFrom(totalsToReceive)
                        ) : null}
                      </motion.p>
                    )}
                  </AnimatePresence>

                  {!user && <span className="message">Befriend your money.</span>}
                </motion.h1>

                {user && (
                  <div className="header-buttons">
                    {records.length ? (
                      <motion.button
                        className="header-button reports-btn"
                        onClick={() => {
                          showReports();
                        }}
                        tabIndex="-1"
                        initial={{ x: 0, width: 40 }}
                        animate={reportsVisible ? { x: "100%", width: 80 } : { x: 0, width: 40 }}
                        transition={{ duration: 0.4, ease: "circOut" }}
                      >
                        <img
                          src={totalIcon}
                          alt="See monthly totals"
                          title="See monthly totals"
                          draggable="false"
                        />
                      </motion.button>
                    ) : null}

                    <motion.button
                      className="header-button profile-btn"
                      onClick={() => {
                        setProfileVisible(true);
                      }}
                      tabIndex="-1"
                      initial={{ scale: 1, opacity: 1 }}
                      animate={
                        reportsVisible
                          ? { scale: 0.85, opacity: 0 }
                          : { scale: 1, opacity: 1 }
                      }
                      transition={{ duration: 0.4, ease: "circOut" }}
                    >
                      <img
                        src={user.photoURL}
                        alt={user.displayName}
                        draggable="false"
                      />
                    </motion.button>
                  </div>
                )}


                {/* search */}
                {(user && records.length) ? (
                  <motion.div
                    className="search-container"
                    initial={
                      reportsVisible
                        ? { x: "20px", opacity: 0.5 }
                        : { x: 0, opacity: 1 }
                    }
                    animate={
                      reportsVisible
                        ? { x: "20px", opacity: 0.5 }
                        : { x: 0, opacity: 1 }
                    }
                    transition={{ duration: 0.4, ease: "circOut" }}
                  >
                    <Search />
                  </motion.div>
                ) : null}
              </div>

            </header>
          )}

          {/* grouped records */}
          {user && groupedRecords.length > 0 && (
            <motion.div
              className="grouped-records"
              initial={
                reportsVisible
                  ? { x: "20px", opacity: 0.5 }
                  : { x: 0, opacity: 1 }
              }
              animate={
                reportsVisible
                  ? { x: "20px", opacity: 0.5 }
                  : { x: 0, opacity: 1 }
              }
              transition={{ duration: 0.4, ease: "circOut" }}
            >
              {groupRecordsByDate(filterBySearch(records)).map((groupedRecord, i) =>
                i < maxVisibleRecords ? (
                  <ul className="records" key={i}>
                    <div className="record-group-head">
                      <span className="record-group-date">
                        {recordsFilter === "to-pay" ||
                          recordsFilter === "to-receive"
                          ? `${groupedRecord.relativeDate}, `
                          : ""}
                        {new Date(`${groupedRecord.date}T00:00:00Z`).toDateString()}
                      </span>
                      <span className="record-group-total">
                        {currencyStringFrom(
                          getGroupRecordsTotal(groupedRecord.records)
                        )}
                      </span>
                    </div>
                    {groupedRecord.records.map((record) => (
                      <li
                        className="record-item"
                        key={record.timestamp}
                        onClick={(e) => handleRecordClick(e, record)}
                        {...bind(record)}
                      >
                        <div className="left-side">
                          {record.desc ? (
                            <p className="record-item-desc">{record.desc}</p>
                          ) : (
                            ""
                          )}

                          {record.name ? (
                            <div className="record-item-name-wrapper">
                              <Avvvatars
                                value={record.name}
                                size={18}
                                style="shape"
                              />
                              <p className="record-item-name">{record.name}</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="right-side">
                          <p className="record-item-amt">
                            {currencyStringFrom(record.amt)}
                          </p>

                          {record.method ? (
                            <p className="record-item-method">{`${record.type === "received" ||
                              record.type === "to-receive"
                              ? "in"
                              : "from"
                              } ${record.method}`}</p>
                          ) : (
                            ""
                          )}

                          {(record.type === "to-pay" ||
                            record.type === "to-receive") && (
                              <button
                                className="settle-btn"
                                onClick={(e) => {
                                  handleSettlement(
                                    e,
                                    record.type,
                                    record.amt,
                                    record.desc,
                                    record.name,
                                    record.method,
                                    record.id
                                  );
                                }}
                              >
                                {record.type === "to-pay" ? "Paid" : "Received"}
                              </button>
                            )}
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : null
              )}
              {groupRecordsByDate(filterBySearch(records)).length > MINIMUM_RECORD_COUNT &&
                maxVisibleRecords === MINIMUM_RECORD_COUNT ? (
                <button
                  onClick={showAllRecords}
                  className="see-all-records-btn"
                >
                  See all records
                </button>
              ) : null}
            </motion.div>
          )}

          {/* features and sign in */}
          {!user && !isLoading && (
            <ul className="features">
              <li>
                <span>✍️</span>Make a note of transactions in 4 types: Paid,
                Received, To-Pay, and To-Receive
              </li>
              <li>
                <span>➕</span>See a total of money to be paid and&nbsp;received
              </li>
              <li>
                <span>🧘🏼‍♀️</span>Settle transactions to-pay and to-receive.
              </li>
            </ul>
          )}

          {!user && !isLoading && (
            <motion.button
              onClick={handleSignIn}
              className="signin-btn"
              initial={{ opacity: 0, scale: 0.95, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              transition={{ duration: 0.45, ease: [0.35, 0.45, 0, 1.5] }}
              whileTap={{ scale: 0.95 }}
            >
              Sign in with Google
            </motion.button>
          )}
        </main>

        {!user && !isLoading && <img src={art} alt="" className="art" />}

        {/* new entry form */}
        <AnimatePresence>
          {user && newEntryVisible && (
            <>
              <div className="new-entry-group-wrapper">
                <motion.div
                  className="new-entry-group"
                  variants={entryFormVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  drag="y"
                  dragConstraints={{
                    top: 0,
                    bottom: 0
                  }}
                  dragElastic={0.5}
                  onDragEnd={handleEntryFormDragEnd}
                >
                  {!copyEntryVisible ? (
                    <div className="close-new-entry add-record-header">
                      <h2>New Record</h2>
                      <div className="actions">
                        <button
                          className="action-button close-new-entry-btn"
                          onClick={closeNewEntry}
                        >
                          <CloseIcon />
                        </button>
                      </div>
                      <div className="w-8 h-1 rounded-full bg-black/20 mx-auto add-record-header-handle" />
                    </div>
                  ) : null}

                  {
                    copyEntryVisible ? (
                      <div className="close-new-entry">
                        <h2>Copy Record</h2>
                      </div>
                    ) : null
                  }

                  <form
                    className="add-entry-form"
                    onSubmit={addEntry}
                    ref={addEntryFormRef}
                  >
                    <div className="type-actions">
                      <p>I have</p>
                      <div className="actions">
                        <div
                          className={
                            type === "paid" ? "active button" : "button"
                          }
                          onClick={() => {
                            setType("paid");
                          }}
                        >
                          paid
                        </div>

                        <div
                          className={
                            type === "received" ? "active button" : "button"
                          }
                          onClick={() => {
                            setType("received");
                          }}
                        >
                          received
                        </div>

                        <div
                          className={
                            type === "to-pay" ? "active button" : "button"
                          }
                          onClick={() => {
                            setType("to-pay");
                          }}
                        >
                          to pay
                        </div>

                        <div
                          className={
                            type === "to-receive" ? "active button" : "button"
                          }
                          onClick={() => {
                            setType("to-receive");
                          }}
                        >
                          to receive
                        </div>
                      </div>
                    </div>

                    <div className="add-entry-group">
                      <label htmlFor="amt">
                        {getCurrencySymbol()}
                      </label>
                      <input
                        type="number"
                        name="amt"
                        id="amt"
                        min="0"
                        placeholder="00"
                        inputMode="decimal"
                        autoComplete="off"
                        step={0.001}
                        required
                        ref={newEntryAmtRef}
                      />
                    </div>

                    <div className="add-entry-group">
                      <label htmlFor="desc">for</label>
                      <input
                        type="text"
                        name="desc"
                        id="desc"
                        placeholder="description"
                        inputMode="text"
                        autoComplete="off"
                        required
                        ref={newEntryDescRef}
                      />
                    </div>

                    <div className="add-entry-group">
                      <label htmlFor="name">
                        {type === "received" || type === "to-receive"
                          ? "from"
                          : "to"}
                      </label>
                      <input
                        list="names"
                        name="name"
                        id="name"
                        placeholder="name"
                        onFocus={(e) => e.target.select()}
                        ref={newEntryNameRef}
                      />
                    </div>

                    <div className="add-entry-group">
                      <label htmlFor="method">
                        {type === "received" || type === "to-receive"
                          ? "in"
                          : "from"}
                      </label>
                      <input
                        list="methods"
                        name="method"
                        id="method"
                        placeholder="payment method"
                        onFocus={(e) => e.target.select()}
                        ref={newEntryMethodRef}
                      />
                    </div>

                    <div className="add-entry-group relative date-group">
                      <label htmlFor="date" className="flex shrink-0">
                        on&nbsp;
                        {
                          dayLabel ? <>{dayLabel},</> : <></>
                        }&nbsp;
                      </label>
                      <input type="date" name="date" id="date" defaultValue={getToday()} onChange={(e) => {
                        newEntryDateRef.current.value = e.target.value;
                        updateDayLabel();
                      }} required ref={newEntryDateRef} />
                      <div className="absolute right-0 h-full flex items-center justify-between">
                        <button
                          type="button"
                          className="h-full active:bg-black/5 aspect-square rounded-full flex items-center justify-center -mr-2"
                          onClick={() => {
                            const currentDate = new Date(newEntryDateRef.current.value);
                            currentDate.setDate(currentDate.getDate() - 1);
                            newEntryDateRef.current.value = currentDate.toISOString().split('T')[0];
                            updateDayLabel();
                          }}
                        >
                          <PreviousIcon />
                        </button>
                        <button
                          type="button"
                          className="h-full active:bg-black/5 aspect-square rounded-full flex items-center justify-center"
                          onClick={() => {
                            const currentDate = new Date(newEntryDateRef.current.value);
                            currentDate.setDate(currentDate.getDate() + 1);
                            newEntryDateRef.current.value = currentDate.toISOString().split('T')[0];
                            updateDayLabel();
                          }}
                        >
                          <NextIcon />
                        </button>
                      </div>
                    </div>



                    <div className="add-entry-group">
                      <button className="add-record-btn">Save</button>
                    </div>

                    <datalist id="names">
                      {names.map((name) => (
                        <option key={name} value={name} />
                      ))}
                    </datalist>

                    <datalist id="methods">
                      {methods.map((method) => (
                        <option key={method} value={method} />
                      ))}
                    </datalist>
                  </form>
                </motion.div>

                <motion.div
                  className="dialog-backdrop"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  onClick={closeNewEntry}
                ></motion.div>
              </div>
            </>
          )}
        </AnimatePresence>

        {/* edit entry form */}
        <AnimatePresence>
          {user && editRequest && editEntryVisible && (
            <>
              <div className="new-entry-group-wrapper">
                <motion.div
                  className="new-entry-group"
                  variants={entryFormVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  drag="y"
                  dragConstraints={{
                    top: 0,
                    bottom: 0
                  }}
                  dragElastic={0.5}
                  onDragEnd={handleEntryFormDragEnd}
                >
                  <div className="close-new-entry">
                    <div className="titles">
                      <h2>Edit Record</h2>
                      <p
                        className="edit-entry-added-on"
                        ref={editEntryAddedOnDateRef}
                      ></p>
                    </div>
                    <div className="actions">
                      <button
                        className="action-button delete-entry-button"
                        onClick={() => {
                          handleDeleteRecordClick();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0V0z" fill="none" />
                          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" />
                        </svg>
                      </button>
                      <button
                        className="action-button close-new-entry-btn"
                        onClick={closeEditEntry}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>

                  <form
                    className="add-entry-form"
                    onSubmit={saveEditEntryChanges}
                    ref={editEntryFormRef}
                  >
                    <div className="type-actions">
                      <p>I have</p>
                      <div className="actions">
                        <div
                          className={
                            type === "paid" ? "active button" : "button"
                          }
                          onClick={() => {
                            setType("paid");
                          }}
                        >
                          paid
                        </div>

                        <div
                          className={
                            type === "received" ? "active button" : "button"
                          }
                          onClick={() => {
                            setType("received");
                          }}
                        >
                          received
                        </div>

                        <div
                          className={
                            type === "to-pay" ? "active button" : "button"
                          }
                          onClick={() => {
                            setType("to-pay");
                          }}
                        >
                          to pay
                        </div>

                        <div
                          className={
                            type === "to-receive" ? "active button" : "button"
                          }
                          onClick={() => {
                            setType("to-receive");
                          }}
                        >
                          to receive
                        </div>
                      </div>
                    </div>

                    <div className="add-entry-group">
                      <label htmlFor="amt">
                        {getCurrencySymbol()}
                      </label>
                      <input
                        type="number"
                        name="amt"
                        id="amt"
                        min="0"
                        placeholder="00"
                        inputMode="decimal"
                        autoComplete="off"
                        step={0.001}
                        required
                      />
                    </div>

                    <div className="add-entry-group">
                      <label htmlFor="desc">for</label>
                      <input
                        type="text"
                        name="desc"
                        id="desc"
                        placeholder="description"
                        inputMode="text"
                        autoComplete="off"
                        required
                      />
                    </div>

                    <div className="add-entry-group">
                      <label htmlFor="name">
                        {type === "received" || type === "to-receive"
                          ? "from"
                          : "to"}
                      </label>
                      <input
                        list="names"
                        name="name"
                        id="name"
                        placeholder="name"
                      />
                    </div>

                    <div className="add-entry-group">
                      <label htmlFor="method">
                        {type === "received" || type === "to-receive"
                          ? "in"
                          : "from"}
                      </label>
                      <input
                        list="methods"
                        name="method"
                        id="method"
                        placeholder="payment method"
                      />
                    </div>

                    <div className="add-entry-group date-group">
                      <label htmlFor="date">on&nbsp;</label>
                      <input type="date" name="date" id="date" required />
                    </div>

                    <div className="add-entry-group">
                      <button className="add-record-btn">Save</button>
                    </div>

                    <datalist id="names">
                      {names.map((name) => (
                        <option key={name} value={name} />
                      ))}
                    </datalist>

                    <datalist id="methods">
                      {methods.map((method) => (
                        <option key={method} value={method} />
                      ))}
                    </datalist>
                  </form>
                </motion.div>

                <motion.div
                  className="dialog-backdrop"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  onClick={closeEditEntry}
                ></motion.div>
              </div>
            </>
          )}
        </AnimatePresence>

        {/* empty state */}
        {user && !isLoading && !records.length && (
          <div className="empty-state">
            <p className="toy">{currentToy.current}</p>
            <p className="message">
              Have a {currentAdjective.current} {getWeekday(getTime().day)}!
            </p>
          </div>
        )}

        {/* open new entry button */}
        {user && !isLoading && (
          <div className="open-new-entry-container">
            <div className="open-new-entry-wrapper">
              <button className="open-new-entry" onClick={openNewEntry}>
                <img src={coinIcon} className="coin" alt="" />
                <img src={coinPressedIcon} className="pressed" alt="" />
              </button>
            </div>
          </div>
        )}

        {user && reportsVisible && (
          <>
            <motion.div
              className="reports"
            >
              <div className="reports-header">
                <div className="titles">
                  <h3>
                    <motion.div
                      className="title"
                      initial={{ x: "-20px", opacity: 0.5 }}
                      animate={
                        reportsVisible
                          ? { x: 0, opacity: 1 }
                          : { x: "-20px", opacity: 0.5 }
                      }
                      transition={{ duration: 0.4, ease: "circOut" }}
                    >
                      Total {recordsFilter}
                    </motion.div>
                    <div className="subtext"></div>
                  </h3>
                </div>

                <div className="actions">
                  <motion.button
                    onClick={hideReports}
                    initial={{ x: "-100%", width: 40 }}
                    animate={reportsVisible ? { x: 0, width: 80 } : { x: "-100%", width: 40 }}
                    transition={{ duration: 0.4, ease: "circOut" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 0 24 24"
                      width="24"
                    >
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M19 11H7.83l4.88-4.88c.39-.39.39-1.03 0-1.42-.39-.39-1.02-.39-1.41 0l-6.59 6.59c-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L7.83 13H19c.55 0 1-.45 1-1s-.45-1-1-1z" />
                    </svg>
                  </motion.button>
                </div>
              </div>

              <motion.div
                className="reports-body"
                initial={{ x: "-20px", opacity: 0.5 }}
                animate={
                  reportsVisible
                    ? { x: 0, opacity: 1 }
                    : { x: "-20px", opacity: 0.5 }
                }
                transition={{ duration: 0.4, ease: "circOut" }}
              >
                {monthTotals.map((item, index) => (
                  <button key={index} className="reports-list-item" onClick={() => openMonthReport(item, index)}>
                    <div className="reports-summary-group">
                      <div className="month-name">
                        <p>{item.month}</p>
                      </div>

                      <div className="month-total-amt">
                        <p>{currencyStringFrom(item.amt)}</p>
                        <ReportTrend trend={item.trend} />
                      </div>
                    </div>

                    <div className="reports-action-btn icon-btn">
                      <AddIcon />
                    </div>
                  </button>
                ))}
              </motion.div>
            </motion.div>
          </>
        )}

        <Dialog open={monthReport} onDismiss={closeMonthReport}>
          {monthReport ? (
            <>
              <div className="month-report-header">
                <div className="reports-summary-group">
                  <div className="month-name">
                    <p>{monthReport.month}</p>
                  </div>

                  <div className="month-total-amt">
                    <p>{currencyStringFrom(monthReport.amt)}</p>
                    <ReportTrend trend={monthReport.trend} full={true} lastMonth={monthReport.lastMonth} />
                  </div>
                </div>
              </div>
              <div className="month-report-body">
                {monthReport.methodGroup.length ? (
                  <ol className="month-top-records-chips">
                    {monthReport.methodGroup.map((record, index) => (
                      <li key={record.id}>
                        <p>{currencyStringFrom(record.amt)}</p>
                        <span className="chip-label">
                          {recordsFilter === 'paid' || recordsFilter === 'to-pay' ? 'from' : 'in'} {record.method ? record.method : 'Others'}
                        </span>
                      </li>
                    ))}
                  </ol>
                ) : null}

                {monthReport.nameGroup.hasRecords ? (
                  <ol className="month-top-records">
                    <p className="month-top-records-title">
                      People <span className="serif">&</span> Places
                    </p>
                    {monthReport.nameGroup.map((record, index) => (
                      record.name ? (
                        <li key={record.id}>
                          <div className="month-top-record">
                            <Avvvatars
                              value={record.name}
                              size={18}
                              style="shape"
                            />
                            {record.name}
                          </div>
                          <p>{currencyStringFrom(record.amt)}</p>
                        </li>
                      ) : null
                    ))}
                  </ol>
                ) : null}

                {monthReport.descGroup.hasRecords ? (
                  <ol className="month-top-records">
                    <p className="month-top-records-title">All Records</p>
                    {monthReport.descGroup.map((record, index) => (
                      <li key={record.id}>
                        <p className="month-top-record">{record.desc}</p>
                        <p className="month-top-record">{currencyStringFrom(record.amt)} </p>
                      </li>
                    ))}
                  </ol>
                ) : null}
              </div>
            </>
          ) : null}
        </Dialog>

        {/* tab bar */}
        <Tabbar />

        {/* profile */}
        <AnimatePresence>
          {user && profileVisible && (
            <>
              <div className="new-entry-group-wrapper">
                <motion.div
                  className="new-entry-group"
                  variants={entryFormVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  drag="y"
                  dragConstraints={{
                    top: 0,
                    bottom: 0
                  }}
                  dragElastic={0.5}
                  onDragEnd={handleEntryFormDragEnd}
                >
                  <div className="close-new-entry">
                    <div className="profile-group"></div>
                    <div className="actions">
                      <button
                        className="action-button close-new-entry-btn"
                        onClick={closeProfile}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>

                  <div className="about">
                    <div className="about-wrapper">
                      <div className="avatar">
                        <img src={user.photoURL} alt="" draggable="false" />
                        <img
                          src={user.photoURL}
                          alt=""
                          draggable="false"
                          className="shadow"
                        />
                      </div>
                      <h2>
                        {user.displayName.split(" ")[0]}
                        {user.displayName.split(" ")[0].endsWith("s")
                          ? "'"
                          : "'s"}{" "}
                        Paysave
                      </h2>
                      <p>
                        Friends with money{" "}
                        {firstRecordDate
                          ? "since " + fullDateFormat.format(firstRecordDate)
                          : null}
                      </p>

                      {navigator.share !== undefined ? (
                        <button
                          className="invite-btn"
                          onClick={handleInviteBtn}
                        >
                          <span>Share friendship</span>
                        </button>
                      ) : null}

                      <div className="settings-group">
                        <button
                          className="settings-line"
                          onClick={toggleSounds}
                        >
                          <div className="settings-line-label-group">
                            {soundsEnabled ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M3 10v4c0 .55.45 1 1 1h3l3.29 3.29c.63.63 1.71.18 1.71-.71V6.41c0-.89-1.08-1.34-1.71-.71L7 9H4c-.55 0-1 .45-1 1zm13.5 2c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 4.45v.2c0 .38.25.71.6.85C17.18 6.53 19 9.06 19 12s-1.82 5.47-4.4 6.5c-.36.14-.6.47-.6.85v.2c0 .63.63 1.07 1.21.85C18.6 19.11 21 15.84 21 12s-2.4-7.11-5.79-8.4c-.58-.23-1.21.22-1.21.85z" />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="24"
                                viewBox="0 0 24 24"
                                width="24"
                              >
                                <path d="M3.63 3.63c-.39.39-.39 1.02 0 1.41L7.29 8.7 7 9H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h3l3.29 3.29c.63.63 1.71.18 1.71-.71v-4.17l4.18 4.18c-.49.37-1.02.68-1.6.91-.36.15-.58.53-.58.92 0 .72.73 1.18 1.39.91.8-.33 1.55-.77 2.22-1.31l1.34 1.34c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L5.05 3.63c-.39-.39-1.02-.39-1.42 0zM19 12c0 .82-.15 1.61-.41 2.34l1.53 1.53c.56-1.17.88-2.48.88-3.87 0-3.83-2.4-7.11-5.78-8.4-.59-.23-1.22.23-1.22.86v.19c0 .38.25.71.61.85C17.18 6.54 19 9.06 19 12zm-8.71-6.29l-.17.17L12 7.76V6.41c0-.89-1.08-1.33-1.71-.7zM16.5 12c0-1.77-1.02-3.29-2.5-4.03v1.79l2.48 2.48c.01-.08.02-.16.02-.24z" />
                              </svg>
                            )}
                            <span className="settings-line-label">Sounds</span>
                          </div>
                          <div className="settings-line-switch-group">
                            <span className="settings-line-switch-label">
                              {soundsEnabled ? "On" : "Off"}
                            </span>
                            <Switch
                              checked={soundsEnabled}
                              setChecked={toggleSounds}
                            />
                          </div>
                        </button>

                        <label htmlFor="currency-selector" className="settings-line">
                          <div className="settings-line-label-group">
                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"><g><rect fill="none" height="24" width="24" /><rect fill="none" height="24" width="24" /></g><g><path d="M18,4H6C3.79,4,2,5.79,2,8v8c0,2.21,1.79,4,4,4h12c2.21,0,4-1.79,4-4V8C22,5.79,20.21,4,18,4z M16.14,13.77 c-0.24,0.2-0.57,0.28-0.88,0.2L4.15,11.25C4.45,10.52,5.16,10,6,10h12c0.67,0,1.26,0.34,1.63,0.84L16.14,13.77z M6,6h12 c1.1,0,2,0.9,2,2v0.55C19.41,8.21,18.73,8,18,8H6C5.27,8,4.59,8.21,4,8.55V8C4,6.9,4.9,6,6,6z" /></g></svg>
                            <div className="settings-line-label">
                              Currency
                            </div>
                          </div>

                          <select id="currency-selector" value={currencyCode} onChange={handleCurrencyChange}>
                            {currencyCodes.map((currency) => (
                              <option key={currency.code} value={currency.code}>
                                {currency.code} - {currency.currency}
                              </option>
                            ))}
                          </select>

                          <span className="settings-line-value">
                            {`${currencyCode} ${getCurrencySymbol()}`}
                          </span>
                        </label>

                        <label htmlFor="monthStartDate" className="settings-line">
                          <div className="settings-line-label-group">
                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7zm4 0h2v2h-2zm4 0h2v2h-2z" /></svg>
                            <div className="settings-line-label">
                              Group Reports by
                            </div>
                          </div>

                          <select id="monthStartDate" value={monthStartDate} onChange={handleChange}>
                            <option value="1">1st of each month</option>
                            <option value="10">10th of each month</option>
                          </select>

                          <span className="settings-line-value">
                            {monthStartDate === 1 ? '1st of each month' : '10th of each month'}
                          </span>
                        </label>


                        <a
                          className="settings-line"
                          href="mailto:paysave@akshardave.com?subject=Hello!"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <div className="settings-line-label-group">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M0 0h24v24H0z" fill="none" />
                              <path d="M20 6H10v5c0 .55-.45 1-1 1s-1-.45-1-1V4h5c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1v5H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2z" />
                            </svg>
                            <span className="settings-line-label">
                              Give feedback / Contact
                            </span>
                          </div>
                        </a>

                        <button
                          className="settings-line"
                          onClick={handleLogout}
                        >
                          <div className="settings-line-label-group">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              enableBackground="new 0 0 24 24"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <g>
                                <path d="M0,0h24v24H0V0z" fill="none" />
                              </g>
                              <g>
                                <g>
                                  <path d="M5,5h6c0.55,0,1-0.45,1-1v0c0-0.55-0.45-1-1-1H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h6c0.55,0,1-0.45,1-1v0 c0-0.55-0.45-1-1-1H5V5z" />
                                  <path d="M20.65,11.65l-2.79-2.79C17.54,8.54,17,8.76,17,9.21V11h-7c-0.55,0-1,0.45-1,1v0c0,0.55,0.45,1,1,1h7v1.79 c0,0.45,0.54,0.67,0.85,0.35l2.79-2.79C20.84,12.16,20.84,11.84,20.65,11.65z" />
                                </g>
                              </g>
                            </svg>
                            <span className="settings-line-label">Log out</span>
                          </div>
                        </button>
                      </div>

                      <p>
                        Made for you by{" "}
                        <a
                          href="https://akshardave.com"
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          Akshar Dave
                        </a>{" "}
                        and friends at{" "}
                        <a
                          href="https://kindgarden.org"
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          Kind Garden
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  className="dialog-backdrop"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  onClick={closeProfile}
                ></motion.div>
              </div>
            </>
          )}
        </AnimatePresence>
      </PreferencesContext.Provider>
    </div>
  );
};

export default App;
