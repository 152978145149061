import './ReportTrend.scss';

const ReportTrend = (props) => {
    const { trend, full, lastMonth } = props;
    if (!trend) return null;

    return (
        <p className={`report-trend ${trend.description} ${full ? 'report-trend-expanded' : ''}`}>
            {trend.percent === "0%" ? (
                <span className="trend-description">Same as {lastMonth}</span>
            ) : (
                <>
                    {trend.description === "lower" ? <span className="trend-sign">-</span> : null}
                    <span>{trend.percent}</span>
                    <span className="trend-amount">{trend.amt}</span>
                    <span className="trend-description">
                        {trend.description === 'upper' ? 'more' : 'less'} than {lastMonth}
                    </span>
                </>
            )}
        </p>
    )
}

export default ReportTrend