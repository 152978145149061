import React from 'react'
import { Drawer } from 'vaul';
import './Dialog.scss';
import CloseIcon from '../../icons/CloseIcon';

const Dialog = (props) => {
    const { open, children, onDismiss } = props;
    return (
        <Drawer.Root open={open} data-state={open} onClose={onDismiss}>
            <Drawer.Portal>
                <Drawer.Overlay className="dialog-backdrop" />
                <Drawer.Content className="dialog-content">
                    <div className="dialog-handle"></div>
                    
                    <div className="dialog-content-wrapper">
                    <Drawer.Close className='dialog-close-btn icon-btn' onClick={onDismiss}>
                        <CloseIcon />
                    </Drawer.Close>
                        {children}
                    </div>
                </Drawer.Content>
            </Drawer.Portal>
        </Drawer.Root >
    )
}

export default Dialog;