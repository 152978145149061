import { useState, useEffect } from 'react';

const IP_API_URL = 'https://api.ipregistry.co/?key=i6oqckefuja5ta30';
const DEFAULT_CURRENCY_CODE = 'USD';
const STORAGE_KEY = 'paysave_currencyCode';

const useUserCurrencyCode = () => {
    const [currencyCode, setCurrencyCode] = useState('');

    useEffect(() => {
        const storedCurrencyCode = localStorage.getItem(STORAGE_KEY);
        if (storedCurrencyCode) {
            setCurrencyCode(storedCurrencyCode);
        } else {
            fetchCurrencyCode();
        }
    }, []);

    const fetchCurrencyCode = async () => {
        try {
            const response = await fetch(IP_API_URL);
            const data = await response.json();
            const fetchedCurrencyCode = data.currency.code;
            setCurrencyCode(fetchedCurrencyCode);
        } catch (error) {
            console.error('Error fetching currency code:', error);
            setCurrencyCode(DEFAULT_CURRENCY_CODE);
        }
    };

    useEffect(() => {
        localStorage.setItem(STORAGE_KEY, currencyCode);
    }, [currencyCode]);

    return { currencyCode, setCurrencyCode };
};

export default useUserCurrencyCode;
