import { useId, useRef } from 'react';
import './Switch.scss';

const Switch = (props) => {
    const { checked = false, setChecked } = props;

    const switchID = useId();

    const handleChange = (e) => {
        setChecked(current => e.target.checked);
    }

    return (
        <>
            <label className={`switch-track ${checked ? 'checked' : ''}`} htmlFor={switchID}>
                <input type="checkbox" id={switchID} checked={checked} onChange={handleChange} hidden />
                <div className="switch-handle"></div>
            </label>
        </>
    )
}

export default Switch;
