import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCde3yqfiZuR2OR2re5GqAGtC1p0GAJFP8",
  authDomain: "paysave-app.firebaseapp.com",
  projectId: "paysave-app",
  storageBucket: "paysave-app.appspot.com",
  messagingSenderId: "780452901092",
  appId: "1:780452901092:web:c9f44f9fe5c0df62a73cf1",
  databaseURL: "https://paysave-app-default-rtdb.asia-southeast1.firebasedatabase.app"
};

const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
export const storeDB = getFirestore(app);