import React, { useContext } from 'react'
import PreferencesContext from '../../contexts/PreferencesContext'
import './Tabbar.scss';

import paidIcon from '../../images/icons/paid.svg';
import receivedIcon from '../../images/icons/received.svg';
import toPayIcon from '../../images/icons/to_pay.svg';
import toReceiveIcon from '../../images/icons/to_receive.svg';

import paidFilledIcon from '../../images/icons/paid_filled.svg';
import receivedFilledIcon from '../../images/icons/received_filled.svg';
import toPayFilledIcon from '../../images/icons/to_pay_filled.svg';
import toReceiveFilledIcon from '../../images/icons/to_receive_filled.svg';

export default function Tabbar() {
    const { user, isLoading } = useContext(PreferencesContext);
    return (
        user && !isLoading && (
            <div className="tabbar-wrapper">
                <div className="records-tabbar">
                    <Tab
                        tabName="paid"
                        icon={paidIcon}
                        activeIcon={paidFilledIcon}
                        title="Paid"
                    />

                    <Tab
                        tabName="received"
                        icon={receivedIcon}
                        activeIcon={receivedFilledIcon}
                        title="Received"
                    />

                    <Tab
                        tabName="to-pay"
                        icon={toPayIcon}
                        activeIcon={toPayFilledIcon}
                        title="To Pay"
                    />

                    <Tab
                        tabName="to-receive"
                        icon={toReceiveIcon}
                        activeIcon={toReceiveFilledIcon}
                        title="To Receive"
                    />
                </div>
            </div>
        )

    )
}

const Tab = (props) => {
    const { tabName, icon, activeIcon, title } = props;
    const { recordsFilter, setRecordsFilter, setReportsVisible } = useContext(PreferencesContext);

    const handleTabClick = () => {
        setRecordsFilter(current => tabName);

        if (recordsFilter === tabName) {
            window.scrollTo(0, 0);
            setReportsVisible(false);
        }
    }

    return (
        <button
            className={`tab ${recordsFilter === tabName ? 'active' : ''}`}
            onClick={handleTabClick}
            title={title}
        >
            <img src={recordsFilter === tabName ? activeIcon : icon} alt={title} />
        </button>
    )
}