import React, { useContext, useEffect } from 'react'
import PreferencesContext from '../../contexts/PreferencesContext'
import './Snackbar.scss';
import checkmarkFilledIcon from '../../images/icons/checkmark_filled.svg';
import { useAnimate } from 'framer-motion';

export default function Snackbar() {
  const { snackbar, setSnackbar } = useContext(PreferencesContext);
  const [scope, animate] = useAnimate()

  // animate the snackbar when text changes
  useEffect(() => {
    if (!snackbar.visible) return;

    const sequence = [
      // in
      [scope.current, {
        opacity: 1,
        y: '100%',
        scale: 1,
        filter: 'brightness(1)',
      }, { type: "spring", bounce: 0.2, duration: 0.6, }],
      // wait
      // prepare to leave
      [scope.current, {
        scale: 0.9,
        filter: 'brightness(0.8)',
      }, { delay: 1, duration: 1, type: "spring", stiffness: 200, damping: 200, }],
      // leave
      [scope.current, {
        scale: 0.75,
        y: '-150%',
      }, { type: "tween", ease: 'anticipate' }],
      [scope.current, {
        opacity: 0
      }, { duration: 0.01, }]
    ]

    animate(sequence).then(() => {
      setSnackbar(prev => ({
        ...prev,
        visible: false,
      }));
    })

  }, [snackbar]);

  return (
    <div className="snackbar-wrapper" ref={scope}>
      <div className="snackbar">
        <div className="snackbar-icon">
          <img src={checkmarkFilledIcon} loading="eager" alt="" />
        </div>
        <p className="snackbar-message">{snackbar.message}</p>
      </div>
    </div>
  )
}
